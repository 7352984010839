import { reactive, readonly } from 'vue'

type tState = {
  cancelNextNavigation: any,
}

type tAppStore = {
  state: tState,
  getRawState: () => tState,
  cancelNextNavigation: () => void,
}

const state = reactive({
  cancelNextNavigation: false,
})

function cancelNextNavigation (): void {
  state.cancelNextNavigation = true
}

const appStore: tAppStore = {
  state: readonly(state),
  getRawState: () => state,
  cancelNextNavigation,
}

export default appStore
