
import { ref, toRef } from 'vue'
import { useI18n } from '@/lang/lang'
import userStore from '@/stores/user.store'

export default {
  name: 'HeaderAuth',
  setup (): any {
    const locales = [
      { lang: 'EN' },
      { lang: 'JA' },
    ]
    const locale: any = ref(locales[0])
    const i18n: any = useI18n()
    const switchLanguage = (e: any) => i18n.switch(locale.value.lang.toLowerCase(), e)
    const user = toRef(userStore.state, 'user')

    return {
      locale,
      locales,
      switchLanguage,
      user,
    }
  },
}
