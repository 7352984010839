
import { defineComponent } from 'vue'
import { provideI18n } from '@/lang/lang'

import 'primeflex/primeflex.css'
import 'primeicons/primeicons.css'
import '@/styles/globals.scss'

// TODO: provide here all the App stores

export default defineComponent({
  name: 'CodeAwarenessPortal',
  setup () {
    provideI18n({
      locale: 'en',
    })

    return {}
  },
})
