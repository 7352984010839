
import HeaderAuth from '@/components/header-auth.vue'
import CAFooter from '@/components/ca-footer.vue'

export default {
  name: 'LayoutAuth',
  components: {
    HeaderAuth,
    CAFooter,
  }
}
