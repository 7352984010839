
import Toast from 'primevue/toast'
import CAHeader from '@/components/ca-header.vue'
import CAFooter from '@/components/ca-footer.vue'

export default {
  name: 'LayoutDefault',
  components: {
    CAHeader,
    CAFooter,
    Toast,
  },
  setup (): any {
    return {
      bgImage: require('@/assets/peer-bg-oneline.jpg'),
    }
  },
}
