
import { shallowRef, watch } from 'vue'
import { useRoute } from 'vue-router'

import { syncStores } from '@/stores' // TODO: make this a plugin, with install function
import AppLayoutDefault from './default.vue'

export default {
  name: 'AppLayout',
  async setup (): Promise<any> {
    const layout = shallowRef(AppLayoutDefault)
    const route = useRoute()

    watch(
      () => route.meta,
      async meta => {
        const component = meta?.layout && await require(`@/layouts/${meta.layout}.vue`)
        layout.value = component?.default || AppLayoutDefault
      },
    )

    await syncStores()

    return { layout }
  },
  async onBeforeUnmount () {
    await syncStores()
  },
}
