import { reactive, readonly } from 'vue'
import { set } from 'idb-keyval'

type tState = {
  readonly user: {},
  readonly tokens: {},
}

type tUserStore = {
  state: tState,
  getRawState: any,
  setUser: any,
  setTokens: any,
}

const state = reactive({
  user: {},
  tokens: {},
})

function setUser (user: any): void {
  state.user = user
  set('userStore', JSON.stringify(state))
}

function setTokens (tokens: any): void {
  state.tokens = tokens
  set('userStore', JSON.stringify(state))
}

function getRawState () {
  return state
}

const userStore: tUserStore = {
  state: readonly(state),
  getRawState,
  setUser,
  setTokens,
}

export default userStore
