import { ref, provide, inject } from 'vue'

const DEFAULT_LANG = 'en'
const i18nSymbol = 'cA-i18n' // TODO: why a Symbol('i18n') here breaks the HMR ?

function findKey (key: string, root: any): string {
  if (!key || !root) return ''
  const chain = key.split('.')
  const head = chain.shift() || ''
  return root[key] || findKey(chain.join('.'), root[head])
}

const createI18n = (config: any) => {
  const messages: any = ref({})
  messages[config.locale] = require(`./i18n/${config.locale}.json`)
  const locale = ref(config.locale)

  return {
    locale,
    messages,
    t (key: string) {
      // TODO: interpolate expressions, such that we can translate stuff like "Wecome ${user.name}. This is your profile page."
      const root = messages[locale.value]
      return findKey(key, root) || findKey(key, messages[DEFAULT_LANG])
    },
    async switch (lang: string) {
      messages[lang] = await require(`./i18n/${lang}.json`)
      locale.value = lang
    }
  }
}

export function provideI18n (i18nConfig: any) {
  const i18n = createI18n(i18nConfig)
  provide(i18nSymbol, i18n)
}

export function useI18n () {
  const i18n = inject(i18nSymbol)
  if (!i18n) throw new Error('No i18n provided!!!')

  return i18n
}
