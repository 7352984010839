import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderAuth = _resolveComponent("HeaderAuth")!
  const _component_CAFooter = _resolveComponent("CAFooter")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_HeaderAuth),
    _createElementVNode("div", null, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _createVNode(_component_CAFooter)
  ], 64))
}