
import { ref } from 'vue'

import { useI18n } from '@/lang/lang'

export default {
  name: 'CAHeader',
  setup (): any {
    const locales = [
      { lang: 'EN' },
      { lang: 'JA' },
    ]
    const locale: any = ref(locales[0])
    const i18n: any = useI18n()
    const switchLanguage = (e: any) => i18n.switch(locale.value.lang.toLowerCase(), e)

    return {
      locale,
      locales,
      switchLanguage,
    }
  },
}
