import { createApp } from 'vue'

/* import PrimeVue and some commonly used components */
import PrimeVue from 'primevue/config'
import InputText from 'primevue/inputtext'
import Button from 'primevue/button'
import Card from 'primevue/card'
import Toast from 'primevue/toast'
import ToastService from 'primevue/toastservice'
import Dropdown from 'primevue/dropdown'
import ProgressSpinner from 'primevue/progressspinner'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faCoffee } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import App from './App.vue'
import router from './router'
import AppLayout from '@/layouts/AppLayout.vue'

import 'primevue/resources/themes/saga-blue/theme.css'
import 'primevue/resources/primevue.min.css'

library.add(faCoffee)

createApp(App)
  .use(router)
  .use(PrimeVue)
  .use(ToastService)
  .component('font-awesome-icon', FontAwesomeIcon)
  .component('AppLayout', AppLayout)
  .component('InputText', InputText)
  .component('Card', Card)
  .component('Button', Button)
  .component('Dropdown', Dropdown)
  .component('ProgressSpinner', ProgressSpinner)
  .component('Toast', Toast)
  .mount('#app')
