import { set, get } from 'idb-keyval'

import { appStore, userStore } from './'

const loaded: any = {}

async function syncStore (storeName: string, store: any) {
  const state = store.getRawState()
  if (loaded[storeName]) {
    await set(storeName, JSON.stringify(state))
  } else {
    const stateFromIndexedDB = await get(storeName)
    if (stateFromIndexedDB) {
      const newState = JSON.parse(stateFromIndexedDB)
      for (const key of Object.keys(state)) state[key] = newState[key]
    }
    loaded[storeName] = true
  }
}

export default async function syncStores () {
  await syncStore('appStore', appStore)
  await syncStore('userStore', userStore)
}
