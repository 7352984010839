import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'Login',
    redirect: '/',
  },
  {
    path: '/sync',
    name: 'Sync',
    component: () => import(/* webpackChunkName "auth" */ '@/views/Sync.vue'),
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '@/views/About.vue'),
  },
  {
    path: '/auth/resetPassword',
    name: 'resetPassword',
    component: () => import(/* webpackChunkName: "auth" */ '@/views/ResetPassword.vue'),
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "user" */ '@/views/Dashboard.vue'),
    meta: { layout: 'auth' },
  },
  {
    path: '/user/:id',
    name: 'User',
    component: () => import(/* webpackChunkName: "user" */ '@/views/User.vue'),
    meta: { layout: 'auth' },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
