<template>
  <footer>
    Copyright 2020-2021, CodeAwareness
  </footer>
</template>

<script>
export default {
  name: 'CAFooter',
}
</script>

<style scoped lang="scss">
@import '@/styles/variables.scss';

footer {
  border-top: 1px solid $teal;
  padding: 1em;
  text-align: center;
  font-size: smaller;
  color: $teal;
}
</style>
